import FlexContainer from "@/design-system-v2/FlexContainer";
import Typography from "@/design-system-v3/Typography";
import Box from "@/design-system/Box";
import Icon from "@/design-system/Icon";
import { IconType, IconVarient } from "@/design-system/Icon/types";
import { theme } from "@/theme";
import Link from "next/link";
import styled from "styled-components";
import NavbarAcountInfo from "./NavbarAccountInfo";

const MenuIcon = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  margin-left: ${theme.spacing(2)};
  justify-content: center;
  cursor: pointer;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    display: none;
  }
`;

const RightNavStyle = styled.div`
  grid-column: span 2;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    grid-column: span 3;
  }
`;

const RightNav = ({
  setIsopenSidebar,
  isOpenSidebar,
  disableCta,
  showContactUs,
}: any) => {
  return (
    <RightNavStyle>
      <Box display={"inherit"} alignItems={"center"}>
        {showContactUs && (
          <Link href={{ pathname: "/contact-us" }}>
            <FlexContainer
              borderRadius={theme.radius(2)}
              alignItems={"center"}
              gap={theme.spacing(4)}
              width={144}
              flexDirection={"row"}
              px={theme.spacing(5)}
              py={theme.spacing(3)}
              background={"rgba(0, 0, 0, 0.10)"}
            >
              <Typography
                varient="bodys"
                weightVarient="semibold"
                color={theme.colors.base.white}
              >
                Contact Us
              </Typography>
              <Icon
                size={16}
                color={theme.colors.base.white}
                type={"message-chat-circle"}
                varient={IconVarient.duotone}
              />
            </FlexContainer>
          </Link>
        )}

        {!disableCta && (
          <NavbarAcountInfo isWeb displayProp={{ _: "none", md: "block" }} />
        )}
      </Box>

      {!disableCta && (
        <MenuIcon onClick={() => setIsopenSidebar(!isOpenSidebar)}>
          <Icon
            type={IconType["menu-04"]}
            varient={IconVarient.solid}
            color={theme.colors.base.white}
          />
        </MenuIcon>
      )}
    </RightNavStyle>
  );
};

export default RightNav;
