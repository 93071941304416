import Link from "next/link";
import ExportedImage from "next-image-export-optimizer";
import styled from "styled-components";

import Box from "@/design-system/Box";
import FlexContainer from "@/design-system/FlexContainer";
import GridColumn from "@/design-system/GridColumn";
import Icon from "@/design-system/Icon";
import { IconType, IconVarient } from "@/design-system/Icon/types";

import PrimaryButton from "@/design-system-v3/Button/PrimaryButton";
import Typography from "@/design-system-v3/Typography";

import { theme } from "@/theme";

const PartnersCard = styled.div`
  background-color: ${theme.colors.gray[100]};
  border-radius: 16px;
  grid-column: span 6;
  display: flex;
  align-items: center;
  overflow: hidden;
  padding-left: ${theme.spacing(10)};
`;

const Resources = () => {
  return (
    <GridColumn xs="span 12">
      <Box
        display={"flex"}
        width={"100%"}
        height={"100%"}
        gap={theme.spacing(4)}
        padding={theme.spacing(6)}
      >
        <PartnersCard>
          <div>
            <FlexContainer alignItems={"center"} mb={2}>
              <Typography color={"gray.950"} varient="heading3" weightVarient="semibold">
                Become a <br /> Mentor
              </Typography>
            </FlexContainer>
            <Typography varient="bodys" weightVarient="regular" mb={theme.spacing(13)}>
              Enable young professionals to make their greatest career leap.
            </Typography>
            <Link href={{ pathname: "/apply-as-mentor" }}>
              <PrimaryButton
                color="neutral"
                label="Learn More"
                suffixIcon={
                  <Icon
                    type={IconType["arrow-narrow-right"]}
                    varient={IconVarient.line}
                    color={theme.colors.base.white}
                  />
                }
              />
            </Link>
          </div>
          <ExportedImage
            width={308}
            height={320}
            src={`${process.env.NEXT_PUBLIC_ASSETS}/images-v2/home/become-mentor.png`}
            //src={"${process.env.NEXT_PUBLIC_ASSETS}/images-v2/mega-menu/partners/become-mentor.png"}
            alt="Become a Mentor"
          />
        </PartnersCard>
        <PartnersCard>
          <div>
            <FlexContainer alignItems={"stretch"} mb={2}>
              <Typography color={"gray.950"} varient="heading3" weightVarient="semibold">
                Become a Hiring Partner
              </Typography>
            </FlexContainer>

            <Typography varient="bodys" weightVarient="regular" mb={theme.spacing(13)}>
              Hire highly skilled AEC professionals at zero cost.
            </Typography>
            <Link href={{ pathname: "/hire-from-us" }}>
              <PrimaryButton
                color="neutral"
                label="Learn More"
                suffixIcon={
                  <Icon
                    type={IconType["arrow-narrow-right"]}
                    varient={IconVarient.line}
                    color={theme.colors.base.white}
                  />
                }
              />
            </Link>
          </div>

          <ExportedImage
            width={308}
            height={320}
            src={`${process.env.NEXT_PUBLIC_ASSETS}/images-v2/home/become-hiring-partner.jpg`}
            alt="Become a Hiring Partner"
          />
        </PartnersCard>
      </Box>
    </GridColumn>
  );
};

export default Resources;
