import Box from "@/design-system/Box";
import GridContainer from "@/design-system/GridContainer";
import { AnimatePresence } from "framer-motion";
import { useEffect, useRef } from "react";
import styled from "styled-components";
import MegaMenu from "../MegaMenu/MegaMenu";
import LeftNav from "./LeftNav";
import RightNav from "./RightNav";

const GridContainerStyle = styled(GridContainer)`
  height: 100%;
  justify-content: space-between;
`;

const Navbar = ({
  setIsopenSidebar,
  isOpenSidebar,
  isOpenMegaMenu,
  currentNavigation,
  setCurrentNavigation,
  setisOpenMegaMenu,
  disableCta,
  showContactUs,
  showBimVersion,
}: any) => {
  const ref: any = useRef();

  const megaMenuRef: any = useRef();

  const handleOpenMegaMenu = (name: string) => {
    if (!isOpenMegaMenu) {
      // When mega menu is closed
      setisOpenMegaMenu(true);
      setCurrentNavigation(name);
    } else {
      // When mega menu is open
      if (currentNavigation === name) {
        // Clicked on selected item
        setisOpenMegaMenu(false);
        setCurrentNavigation("");
      } else {
        // Clicked on different selected item
        setCurrentNavigation(name);
      }
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
  }, []);

  const handleClickOutside = (e: any) => {
    if (
      !ref.current?.contains(e.target) &&
      !megaMenuRef?.current?.contains(e.target)
    ) {
      setCurrentNavigation("");
      setisOpenMegaMenu(false);
    }
  };

  return (
    <>
      <GridContainerStyle>
        <LeftNav
          ref={ref}
          currentNavigation={currentNavigation}
          setisOpenMegaMenu={setisOpenMegaMenu}
          isOpenMegaMenu={isOpenMegaMenu}
          handleOpenMegaMenu={handleOpenMegaMenu}
          disableCta={disableCta}
          showBimVersion={showBimVersion}
        />
        <RightNav
          setIsopenSidebar={setIsopenSidebar}
          isOpenSidebar={isOpenSidebar}
          disableCta={disableCta}
          showContactUs={showContactUs}
        />
      </GridContainerStyle>

      <Box position={"absolute"} width="100%" left={0} ref={megaMenuRef}>
        <AnimatePresence>
          {isOpenMegaMenu && <MegaMenu currentNavigation={currentNavigation} />}
        </AnimatePresence>
      </Box>
    </>
  );
};

export default Navbar;
