import Typography from "@/design-system-v3/Typography";
import { TypographyWeightVarient } from "@/design-system-v3/Typography/typography.styles";
import FlexContainer from "@/design-system/FlexContainer";
import { theme } from "@/theme";
import dayjs from "dayjs";
import ExportedImage from "next-image-export-optimizer";
import { FC } from "react";
import { DotStyle } from "./DotStyle";

const ReadInfo: FC<{
  titleColor?: string;
  color?: string;
  dotColor?: string;
  weightVarient?: TypographyWeightVarient;
  blogData: {
    author__img: string;
    author__firstName: string;
    author__lastName: string;
    createdAt: string;
    readtime: string;
  };
}> = ({ titleColor, color, blogData, dotColor, weightVarient }) => {
  return (
    <FlexContainer alignItems={"center"}>
      <ExportedImage
        src={`${process.env.NEXT_PUBLIC_CDN}/images/blog/authors/${blogData?.author__img}`}
        width={24}
        height={24}
        unoptimized
        alt={
          `${blogData?.author__firstName}` +
          " " +
          `${blogData?.author__lastName}`
        }
        style={{
          borderRadius: "50%",
          width: 16,
          height: 16,
        }}
      />
      <Typography
        color={titleColor || "inherit"}
        ml={theme.spacing(2)}
        varient={"bodyxs"}
        weightVarient={weightVarient || "medium"}
      >
        {`${blogData?.author__firstName}` +
          " " +
          `${blogData?.author__lastName}`}
      </Typography>
      <DotStyle color={dotColor ? dotColor : theme.colors.gray[25]} />
      <Typography
        color={color || "inherit"}
        varient={"bodyxs"}
        weightVarient={weightVarient || "medium"}
      >
        {dayjs(blogData?.createdAt).format(`DD MMM'YY`)}
      </Typography>
      <DotStyle color={dotColor ? dotColor : theme.colors.gray[25]} />
      <Typography
        color={color || "inherit"}
        varient={"bodyxs"}
        weightVarient={weightVarient || "medium"}
      >
        {blogData?.readtime}
      </Typography>
    </FlexContainer>
  );
};

export default ReadInfo;
