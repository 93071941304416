import Link from "next/link";
import ExportedImage from "next-image-export-optimizer";
import styled from "styled-components";

import { useGetNextCohortDateQuery } from "@/client/lmsApiClient";

import Badge from "@/design-system/Badge/badge";
import Box from "@/design-system/Box";
import GridContainer from "@/design-system/GridContainer";
import Icon from "@/design-system/Icon";
import { IconVarient } from "@/design-system/Icon/types";
import GradientLine from "@/design-system/Utility/GradientLine";

import Typography from "@/design-system-v3/Typography";

import { formatNextCohortDate } from "@/utils/utils";

import CoursesDynamicData from "@/content/courses/dynamic-data";

import { theme } from "@/theme";

interface styledCourseCardProps {
  hover: boolean;
}

const CourseCard = styled.div<styledCourseCardProps>`
  grid-column: span 4;
  transition: 0.3s;
  border-radius: 4px;
  .know-more {
    opacity: 0;
    transition: 0.3s;
  }

  .imgbox {
    transition: 0.3s;
    border-radius: 4px;
  }

  &:hover {
    background-color: ${(props) => (props.hover ? "white" : "")};
  }

  &:hover .imgbox {
    height: ${(props) => (props.hover ? "120px" : "")};
  }

  &:hover .know-more {
    opacity: ${(props) => (props.hover ? "1" : "0")};
  }
`;

const CardContent = styled.div`
  padding-left: ${theme.spacing(4)};
  padding-right: ${theme.spacing(4)};
  padding-top: ${theme.spacing(6)};
  padding-bottom: ${theme.spacing(0)};
`;

const CourseInfoStyle = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 14px;
  margin-top: 16px;
`;

const Gray100Box = styled.div`
  position: absolute;
  top: -${theme.spacing(10)};
  bottom: 0;
  right: 0;
  width: calc(100% + 2rem);
  left: 0;
  z-index: -1;
  height: calc(100% + ${theme.spacing(30)});
  background-color: ${theme.colors.gray[100]};
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    width: calc(100% + 5rem);
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    width: calc(100% + (100vw - 1200px) / 2);
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.xl}) {
    width: calc(100% + 10rem);
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.xxl}) {
    width: calc(100% + (100vw - 1520px) / 2);
  }
`;

const BadgePosition = styled.div`
  position: absolute;
  top: 16px;
  left: 16px;
`;

const Lists = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: ${theme.spacing(2)};
`;
const Courses = () => {
  const dynamicData = CoursesDynamicData;
  const { data: nextCohortDate } = useGetNextCohortDateQuery();
  const { BIM_A, BIM_C, MCD } = nextCohortDate || {};
  return (
    <>
      <Box gridColumn={"span 9"} paddingLeft={32} py={theme.spacing(6)}>
        <GridContainer alignItems="flex-start" spacing={theme.spacing(8)}>
          <CourseCard hover={true}>
            <Link className="link__" href={{ pathname: "/courses/building-information-modelling" }}>
              <Box height={140} overflow="hidden" className="imgbox">
                <ExportedImage
                  width={320}
                  height={140}
                  src={`${process.env.NEXT_PUBLIC_ASSETS}/images-v2/home/bim.png`}
                  sizes="100vw"
                  style={{
                    width: "100%",
                    // transition: "0.3s",
                    objectFit: "cover",
                  }}
                  alt=""
                />{" "}
                <BadgePosition>
                  <Badge
                    text="Just Launched"
                    colorVarient="primary"
                    badgeStyle="light"
                    dotLeading={true}
                  />
                </BadgePosition>
              </Box>
              <CardContent>
                <Typography
                  varient="heading6"
                  color={"primary.900"}
                  mb={theme.spacing(2)}
                  weightVarient="semibold"
                >
                  BIM Professional <br /> Course for Architects V2.0
                </Typography>
                <Typography varient="bodyxs" mb={theme.spacing(2)} weightVarient="medium">
                  Take your career to the next level by mastering the real-world application of BIM.
                </Typography>
                <CourseInfoStyle>
                  <GradientLine />
                  <div
                    style={{
                      display: "flex",
                      flex: 1,
                      flexDirection: "row",
                      gap: theme.spacing(1),
                    }}
                  >
                    <div>
                      <Typography
                        style={{
                          fontSize: "10px",
                          fontWeight: "400",
                          lineHeight: "16px",
                        }}
                        color={theme.colors.gray[700]}
                      >
                        Next Cohort
                      </Typography>{" "}
                      <Typography
                        style={{
                          fontSize: "10px",
                          fontWeight: "600",
                          lineHeight: "16px",
                        }}
                        color={theme.colors.gray[700]}
                      >
                        {formatNextCohortDate(BIM_A?.date)}
                      </Typography>
                    </div>
                    <div>
                      <Typography
                        style={{
                          fontSize: "10px",
                          fontWeight: "400",
                          lineHeight: "16px",
                        }}
                        color={theme.colors.gray[700]}
                      >
                        Program Duration
                      </Typography>
                      <Typography
                        style={{
                          fontSize: "10px",
                          fontWeight: "600",
                          lineHeight: "16px",
                        }}
                        color={theme.colors.gray[700]}
                      >
                        {" "}
                        {dynamicData.find((c) => c.courseId === "BIM")?.upcomingCohortDuration}
                      </Typography>
                    </div>
                  </div>
                </CourseInfoStyle>
                <Typography
                  varient="bodys"
                  mt={theme.spacing(5)}
                  className="know-more"
                  color={"purple.800"}
                  weightVarient="semibold"
                >
                  Know more
                </Typography>
              </CardContent>
            </Link>
          </CourseCard>

          <CourseCard hover={true}>
            <Link className="link__" href={{ pathname: "/courses/computational-design" }}>
              <Box height={140} overflow="hidden" className="imgbox">
                <ExportedImage
                  width={320}
                  height={140}
                  src={`${process.env.NEXT_PUBLIC_ASSETS}/images-v2/home/mcd.png`}
                  sizes="100vw"
                  style={{
                    width: "100%",
                    objectFit: "cover",
                  }}
                  alt=""
                />{" "}
                {/* <BadgePosition>
                  <Badge
                    text="Recently Launched"
                    dotLeading={true}
                    badgeStyle="dark"
                    colorVarient="blue"
                  />
                </BadgePosition> */}
              </Box>
              <CardContent>
                <Typography
                  varient="heading6"
                  color={"primary.900"}
                  mb={theme.spacing(2)}
                  weightVarient="semibold"
                >
                  Master Computational <br /> Design Course
                </Typography>
                <Typography varient="bodyxs" mb={theme.spacing(2)}>
                  Break into an advanced career by learning to apply the power of computation to the
                  art of design.
                </Typography>
                <CourseInfoStyle>
                  <GradientLine />
                  <div
                    style={{
                      display: "flex",
                      flex: 1,
                      flexDirection: "row",
                      gap: theme.spacing(1),
                    }}
                  >
                    <div>
                      <Typography
                        style={{
                          fontSize: "10px",
                          fontWeight: "400",
                          lineHeight: "16px",
                        }}
                        color={theme.colors.gray[700]}
                      >
                        Next Cohort
                      </Typography>{" "}
                      <Typography
                        style={{
                          fontSize: "10px",
                          fontWeight: "600",
                          lineHeight: "16px",
                        }}
                        color={theme.colors.gray[700]}
                      >
                        {formatNextCohortDate(MCD?.date)}
                      </Typography>
                    </div>
                    <div>
                      <Typography
                        style={{
                          fontSize: "10px",
                          fontWeight: "400",
                          lineHeight: "16px",
                        }}
                        color={theme.colors.gray[700]}
                      >
                        Program Duration
                      </Typography>
                      <Typography
                        style={{
                          fontSize: "10px",
                          fontWeight: "600",
                          lineHeight: "16px",
                        }}
                        color={theme.colors.gray[700]}
                      >
                        {" "}
                        {dynamicData.find((c) => c.courseId === "acm")?.upcomingCohortDuration}
                      </Typography>
                    </div>
                  </div>
                </CourseInfoStyle>
                <Typography
                  varient="bodys"
                  mt={theme.spacing(5)}
                  className="know-more"
                  color={"purple.800"}
                  weightVarient="semibold"
                >
                  Know more
                </Typography>
              </CardContent>
            </Link>
          </CourseCard>
          <CourseCard hover={true}>
            <Link className="link__" href={{ pathname: "/courses/bim-for-civil-engineers" }}>
              <Box height={140} overflow="hidden" className="imgbox">
                <ExportedImage
                  width={320}
                  height={140}
                  src={`${process.env.NEXT_PUBLIC_ASSETS}/images-v2/mega-menu/image_2.jpg`}
                  sizes="100vw"
                  style={{
                    width: "100%",
                    objectFit: "cover",
                  }}
                  alt=""
                />{" "}
                {/* <BadgePosition>
                  <Badge
                    text="New Arrival"
                    dotLeading={true}
                    badgeStyle="light"
                    colorVarient="warning"
                  />
                </BadgePosition> */}
              </Box>
              <CardContent>
                <Typography
                  varient="heading6"
                  color={"primary.900"}
                  mb={theme.spacing(2)}
                  weightVarient="semibold"
                >
                  BIM Professional Course For Civil Engineers
                </Typography>
                <Typography varient="bodyxs" mb={theme.spacing(2)}>
                  Unlock new civil engineering career levels by upskilling and building on
                  real-world projects from AEC industry.
                </Typography>
                <CourseInfoStyle>
                  <GradientLine />
                  <div
                    style={{
                      display: "flex",
                      flex: 1,
                      flexDirection: "row",
                      gap: theme.spacing(1),
                    }}
                  >
                    <div>
                      <Typography
                        style={{
                          fontSize: "10px",
                          fontWeight: "400",
                          lineHeight: "16px",
                        }}
                        color={theme.colors.gray[700]}
                      >
                        Next Cohort
                      </Typography>{" "}
                      <Typography
                        style={{
                          fontSize: "10px",
                          fontWeight: "600",
                          lineHeight: "16px",
                        }}
                        color={theme.colors.gray[700]}
                      >
                        {formatNextCohortDate(BIM_C?.date)}
                      </Typography>
                    </div>
                    <div>
                      <Typography
                        style={{
                          fontSize: "10px",
                          fontWeight: "400",
                          lineHeight: "16px",
                        }}
                        color={theme.colors.gray[700]}
                      >
                        Program Duration
                      </Typography>{" "}
                      <Typography
                        style={{
                          fontSize: "10px",
                          fontWeight: "600",
                          lineHeight: "16px",
                        }}
                        color={theme.colors.gray[700]}
                      >
                        {dynamicData.find((c) => c.courseId === "BIM_C")?.upcomingCohortDuration}
                      </Typography>
                    </div>
                  </div>
                </CourseInfoStyle>

                <Typography
                  varient="bodys"
                  mt={theme.spacing(5)}
                  className="know-more"
                  color={"purple.800"}
                  weightVarient="semibold"
                >
                  Know more
                </Typography>
              </CardContent>
            </Link>
          </CourseCard>
        </GridContainer>
      </Box>
      <Box
        gridColumn={"span 3"}
        py={theme.spacing(15)}
        px={theme.spacing(10)}
        backgroundColor={theme.colors.purple[50]}
      >
        <div
          style={{
            fontSize: "12px",
            fontWeight: "500",
            lineHeight: "20px",
            textTransform: "uppercase",
          }}
          color={theme.colors.gray[600]}
        >
          Innovative Learning
        </div>
        <Typography
          varient="heading5"
          weightVarient="semibold"
          color={"purple.600"}
          mb={theme.spacing(6)}
        >
          <Box as="span" color={"#0C111D"}>
            An
          </Box>{" "}
          Unforgettable Experience
        </Typography>
        <Lists>
          <Icon
            color={theme.colors.purple[500]}
            type={"Globe"}
            varient={IconVarient.line}
            size={24}
          />{" "}
          <Typography varient="bodys" weightVarient="medium" mb={theme.spacing(5)}>
            International Exposure
          </Typography>
        </Lists>
        <Lists>
          <Icon
            color={theme.colors.purple[500]}
            type={"GameController"}
            varient={IconVarient.line}
            size={24}
          />{" "}
          <Typography varient="bodys" weightVarient="medium" mb={theme.spacing(5)}>
            Gamified Experience
          </Typography>
        </Lists>
        <Lists>
          <Icon
            color={theme.colors.purple[500]}
            type={"Chats"}
            varient={IconVarient.line}
            size={24}
          />{" "}
          <Typography varient="bodys" weightVarient="medium" mb={theme.spacing(5)}>
            Personalised Feedback{" "}
          </Typography>
        </Lists>
        <Lists>
          <Icon
            color={theme.colors.purple[500]}
            type={"UserFocus"}
            varient={IconVarient.line}
            size={24}
          />{" "}
          <Typography varient="bodys" weightVarient="medium" mb={theme.spacing(5)}>
            Personalised Skill Mapping
          </Typography>
        </Lists>
        <Lists>
          <Icon
            color={theme.colors.purple[500]}
            type={"Video"}
            varient={IconVarient.line}
            size={24}
          />{" "}
          <Typography varient="bodys" weightVarient="medium" mb={theme.spacing(5)}>
            Interactive Live Sessions{" "}
          </Typography>
        </Lists>
        {/* <Gray100Box /> */}
      </Box>
    </>
  );
};

export default Courses;
